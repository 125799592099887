import { getParentByClassName, getWindowTop } from "./tools";

export function defineHeaderTheme() {
    const firstSection = document.querySelector('.section'),
        header = document.querySelector('.header');
        
    if (firstSection && (firstSection.classList.contains('--bg-black') || firstSection.classList.contains('--bg-red') || firstSection.classList.contains('--bg-dark'))) {
        header.classList.add('--dark-mode');
    }
}

export function navbarScrollBehavior(direction) {
    const scrollTop = getWindowTop(), viewport = window.innerWidth;

    if (scrollTop > 40) {
        if (!window.egoHeader.isFixed) {
            window.egoHeader.isFixed = true;
            window.egoHeader.element.classList.add('--fixed');
            if (!window.egoHeader.timeout) {
                window.egoHeader.timeout = setTimeout(() => {
                    window.egoHeader.navbar.classList.add('--fixed');
                }, 350);
            }
            if (viewport >= 992) window.egoHeader.animation.play();
        }

        if (viewport >= 992 && !window.egoHeader.isCurrentlyHovered) {
            if (direction == 'down') {
                window.egoHeader.element.classList.remove('--active');
                window.egoHeader.animation.play();
            } else {
                window.egoHeader.element.classList.add('--active');
                window.egoHeader.animation.delay(.2).reverse();
            }
        }
    }
    else {
        if (window.egoHeader.isFixed) {
            window.egoHeader.isFixed = false;
            if (window.egoHeader.timeout) {
                clearTimeout(window.egoHeader.timeout);
                window.egoHeader.timeout = null;
            }
            window.egoHeader.navbar.classList.remove('--fixed');
            window.egoHeader.element.classList.remove('--fixed');

            if (viewport >= 992) window.egoHeader.animation.delay(.2).reverse();
        }
    }
}

export function menuBurguerBehavior() {
    document.querySelector('.header__brand-burger')
        .addEventListener('click', () => {
            const header = document.querySelector('.header'),
                body = document.getElementsByTagName('body')[ 0 ];
            if (header.classList.contains('--active')) {
                window.egoHeader.animation.reverse();
                setTimeout(() => {
                    header.classList.remove('--active');
                    body.classList.remove('--lock-scroll');
                }, 200);
            } else {
                header.classList.add('--active');
                body.classList.add('--lock-scroll');
                setTimeout(() => {
                    window.egoHeader.animation.play();
                }, 300);
            }
        });
}

function getCurrentSection(logo) {
    const logoData = logo.getBoundingClientRect()
    const varY = logoData.top + Math.round(logoData.height / 2)
    const varX = (window.innerWidth > 992) ? Math.round(window.innerWidth / 2) : 1;
    const header = document.querySelector('.header');

    header.classList.add('--block');
    let element = document.elementFromPoint(varX, varY);
    header.classList.remove('--block');
    if (element.classList?.contains('section')) return element;
    return getParentByClassName({ element: element, className: 'section' });
}

export function logoBackgroundColorController() {
    const circle = document.querySelector('.logo .circle');
    const header = document.querySelector('.header');
    const currentSection = getCurrentSection(circle);
    if (currentSection 
        && (currentSection.classList.contains('--bg-black') 
        || currentSection.classList.contains('--bg-red') 
        || currentSection.classList.contains('--bg-dark'))
    ) {
        header.classList.add('--dark-mode');
    }
    else {
        header.classList.remove('--dark-mode');
    }
}