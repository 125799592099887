import { docReady } from "./modules/tools";
import { defineHeaderTheme, navbarScrollBehavior, menuBurguerBehavior, logoBackgroundColorController } from "./modules/header";

window.egoHeader = {
    timeout: null,
    element: document.querySelector('.header'),
    navbar: document.querySelector('.header__nav__menu--wrapper'),
    animation: gsap.fromTo('.header__nav__menu--wrapper .btn', { 
        opacity: window.innerWidth >= 992 ? 1 : 0
    }, {
        opacity: window.innerWidth >= 992 ? 0 : 1,
        stagger: 0.05,
        duration: 0.15,
        paused: true
    }),
    isFixed: document.querySelector('.header').classList.contains('--fixed'),
    isCurrentlyHovered: false
};

navbarScrollBehavior();
menuBurguerBehavior();
logoBackgroundColorController();

docReady(() => {
    defineHeaderTheme();
})